import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { styled, Box, Grid, Button, Typography } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { NumberDashboard, SeriesDashboard } from './components/Metrics';
import ButtonAppBar from './components/ButtonAppBar';

export const blue = '#0000ff';
export const green = '#00ff00';
export const red = '#ff0000';
export const yellow = '#FFFF00';
export const cyan = '#00ffff';

function GroupReport() {
  const { groupId } = useParams();
  const metricChartNames = ['cumulativeEpisodes', 'chronicity'];
  const metricTableNames = ['newUsersByWeek', 'newEpisodesByWeek', 'psfs', 'adherenceRates', 'npsConversion', 'usersAtRisk'];
  const metricCharts = new Map();
  const metricTables = new Map();
  metricChartNames.forEach(name => {
    metricCharts.set(name, '50%');
  });
  metricTableNames.forEach(name => {
    metricTables.set(name, '50%');
  });

  const [groupMetrics, setGroupMetrics] = useState();
  const [numberMetrics, setNumberMetrics] = useState();
  const [groupMetricsSeriesData, setGroupMetricsSeriesData] = useState();

  const GROUP_METRICS = gql`
    query getGroupMetrics($groupID: ID!) {
      getGroupMetrics(groupID: $groupID) {
        group {
          id
          name
        }
        totalGroupUsers {
          name
          value
        }
        distinctAwareUsers {
          name
          value
        }
        totalEvals {
          name
          value
        }
        cumulativeEpisodes {
          name
          data {
            values
          }
          headings
        }
        chronicity {
          name
          data {
            values
          }
          headings
        }
        npsScore {
          name
          value
        }
        numRepeatUsers {
          name
          value
        }
        numVisits {
          name
          value
        }
        numAtRiskImaging {
          name
          value
        }
        numAtRiskSurgery {
          name
          value
        }
        noShowRate {
          name
          value
        }
        enrollmentRate {
          name
          value
        }
        newEpisodesByWeek {
          name
          data {
            values
          }
          headings
        }
        newUsersByWeek {
          name
          data {
            values
          }
          headings
        }
        psfs {
          name
          data {
            values
          }
          headings
        }
        adherenceRates {
          name
          data {
            values
          }
          headings
        }
        npsConversion {
          name
          data {
            values
          }
          headings
        }
      }
    }
  `;
  const { data, loading, error } = useQuery(GROUP_METRICS, { variables: { groupID: groupId } });
  useEffect(() => {
    if (data) {
      setGroupMetrics(data.getGroupMetrics);
      // filter number data
      let numberData = {};
      Object.entries(data.getGroupMetrics).forEach(([key, metric]) => {
        if (typeof metric.value === 'number' || typeof metric.value === 'string') {
          numberData[key] = metric;
        }
      });
      setNumberMetrics(numberData);
      // filter series data
      let seriesData = {};
      Object.entries(data.getGroupMetrics).forEach(([key, metrics]) => {
        if (metricCharts.has(key)) {
          let chartData = { xAxis: [], yAxis: [] };
          metrics.data.forEach(metric => {
            if (metric.values.length >= 2) {
              chartData.xAxis.push(metric.values[0]);
              chartData.yAxis.push(Number(metric.values[1]));
            }
          });
          chartData.name = metrics.name;
          seriesData[key] = chartData;
        }
        if (metricTables.has(key)) {
          seriesData[key] = metrics;
        }
      });
      setGroupMetricsSeriesData(seriesData);
    }
  }, [data]);
  if (error) {
    console.log('error fetching gql data: ' + error);
  }
  if (!groupId) {
    return <div>Please provide a valid groupID</div>;
  }

  const FlexContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
    gap: theme.spacing(2),
  }));

  const FullWidthBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  }));

  return (
    <Box>
      <ButtonAppBar />
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          display: 'flex',
          padding: 4,
        }}
      >
        <FlexContainer>
          <FullWidthBox>
            <Box display="flex" textAlign="left" mt={2}>
              <Typography variant="h2" component="h2">
                {groupMetrics?.group?.name}
              </Typography>
            </Box>
          </FullWidthBox>
          {numberMetrics && <NumberDashboard data={numberMetrics} />}
          {groupMetricsSeriesData && (
            <SeriesDashboard seriesData={groupMetricsSeriesData} metricCharts={metricCharts} metricTables={metricTables} />
          )}
        </FlexContainer>
      </Box>
    </Box>
  );
}

export default GroupReport;
