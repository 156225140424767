import React from 'react';

function Error() {
  return (
    <div>
      <h2>An error has occured</h2>
    </div>
  );
}

export default Error;
