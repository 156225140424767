//import logo from './logo.svg';
import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import Error from './components/Error';
import PrivateRoutes from './PrivateRoutes';
import GroupReport from './GroupReport';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/error" element={<Error />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="/report/:groupId" element={<GroupReport />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
