import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';

function Login() {
  const navigate = useNavigate();
  const navigateHome = response => {
    sessionStorage.setItem('user', JSON.stringify(response));
    navigate('/');
  };
  const errorMessage = error => {
    console.log(error);
    navigate('/error');
  };
  const login = useGoogleLogin({
    onSuccess: navigateHome,
    onError: errorMessage,
  });

  return (
    <div>
      <Box textAlign="center">
        <h2>Aware Health Metrics Dashboard</h2>
        <Button variant="outlined" onClick={login}>
          Log in
        </Button>
      </Box>
    </div>
  );
}

export default Login;
